import React from 'react';
import AddwishComponent from './Components/AddwishRowComponent';
import BlogComponent from './Components/BlogComponent';
import HtmlBlock from './Components/HtmlBlock';
import ImageRow from './Components/ImageRow';
import ProductRowComponent from './Components/ProductRowComponent';
import TextBox from './Components/TextBox';
import { ContentStyles } from './ContentText';

export const convertPropsToObject = (item) => {
  const props = item.properties.reduce((merged, current) => {
    var _a;
    if (
      ((_a =
        current === null || current === void 0 ? void 0 : current.value) ===
        null || _a === void 0
        ? void 0
        : _a.__typename) === 'StringValue'
    ) {
      merged[current.name] = {
        type: current.type,
        value: current.value.value,
        __typename: 'StringValue',
      };
    } else {
      merged[current.name] = { type: current.type, value: current.value };
    }
    return merged;
  }, {});
  return props;
};

const ElementHandler = ({ row, index }) => {
  const type = row.type.toUpperCase();
  index = index + 1;
  // console.log(row);
  switch (type) {
    case 'IMAGE-ROW':
      return <ImageRow data={row} index={index} />;
    case 'PRODUCT-ROW':
      return <ProductRowComponent data={row} index={index} />;
    case 'BLOG':
      return <BlogComponent data={row} index={index} />;
    case 'ADDWISH-ROW':
      return <AddwishComponent data={row} index={index} />;
    case 'TEXT-BOX':
      return <TextBox data={row} index={index} />;
    case 'HTML-BLOCK':
      return <HtmlBlock data={row} index={index} />;
    default:
      return null;
  }
};

export const ContentRender = ({ data, className }) => {
  if (!data || data.items.length === 0) return null;
  return (
    <ContentStyles className={`content-render-wrapper ${className}`}>
      {data.items.map((row, index) => (
        <ElementHandler row={row} key={index} index={index} />
      ))}
    </ContentStyles>
  );
};
