import { Above } from '@jetshop/ui/Breakpoints';
import { ReactComponent as Carot } from '@jetshop/ui/svg/Carrot.svg';
import { styled } from 'linaria/react';
import React, { useEffect, useRef } from 'react';
import { theme } from '../Theming/Theming';

const ScrollSliderWrapper = styled('div')`
  position: relative;
  .slider-track {
    overflow-x: auto;
    /* width */
    ::-webkit-scrollbar {
      height: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #666;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #222;
    }
  }
`;

const SliderItems = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  margin: 0 -0.5rem 1rem;
  > .product-card,
  > .item-card {
    margin: 0.5rem;
    min-width: 60%;
    ${theme.above.md} {
      min-width: 38%;
    }
    ${theme.above.lg} {
      min-width: 28%;
    }
    ${theme.above.xl} {
      min-width: 22%;
    }
  }
`;

const ButtonWrapper = styled('button')`
  position: absolute;
  top: calc(50% - 17.5px);
  width: 35px;
  height: 35px;
  box-shadow: 0px 0px 10px #00000059;
  border-radius: 50%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  opacity: 0.6;
  &:hover {
    opacity: 0.95;
  }
  svg {
    width: 18px;
    height: 18px;
  }
  &.next {
    right: -5px;
    svg {
      transform: rotate(-90deg);
    }
  }
  &.prev {
    left: -5px;
    svg {
      transform: rotate(90deg);
      position: relative;
      left: -1px;
    }
  }
`;

const SliderButton = ({ dir, ...rest }) => (
  <ButtonWrapper className={dir === 'next' ? 'next' : 'prev'} {...rest}>
    <Carot />
  </ButtonWrapper>
);

export const ScrollSlider = ({ children, scrollDistance = 600 }) => {
  const sliderRef = useRef(null);

  useEffect(() => {
    return () => {};
  });

  function scrollContainerBy(distance) {
    sliderRef.current.scrollBy({ left: distance, behavior: 'smooth' });
  }

  return (
    <ScrollSliderWrapper>
      <Above breakpoint="lg">
        {(matches) => {
          if (matches)
            return (
              <>
                <SliderButton
                  dir={'prev'}
                  onClick={() => {
                    scrollContainerBy(-scrollDistance);
                  }}
                />
                <SliderButton
                  dir={'next'}
                  onClick={() => {
                    scrollContainerBy(scrollDistance);
                  }}
                />
              </>
            );
          return null;
        }}
      </Above>
      <div className="slider-track" ref={sliderRef}>
        <SliderItems>{children}</SliderItems>
      </div>
    </ScrollSliderWrapper>
  );
};

/*
USAGE
<ScrollSlider scrollDistance={600} direction="vertical">
  {products.map((product, index) => (
    <ProductCard
      product={product}
      key={product.id}
    />
  ))}
</ScrollSlider>
*/
